import { Observation } from '~/core/schema/observation.schema';
import { ValuesType } from '~/shared/types';

export const NationalSupportPlan = {
  PACEA: 'PACEA',
  CEJ: 'CEJ',
  GLO: 'GLO',
  EXH: 'EXH',
  RSA: 'RSA',
} as const;
export const nationalSupportPlans = Object.values(NationalSupportPlan);
export type NationalSupportPlan = ValuesType<typeof NationalSupportPlan>;

export const Gender = {
  Female: 'FEMALE',
  Male: 'MALE',
  NotDefined: 'NOT_DEFINED',
} as const;
// export const genders = Object.values(Gender);
export type Gender = ValuesType<typeof Gender>;
export const genderTranslation: Record<Gender, string> = {
  [Gender.Female]: 'Femme',
  [Gender.Male]: 'Homme',
  [Gender.NotDefined]: 'Ne se prononce pas',
};

export const Network = {
  FranceTravail: 'FRANCE_TRAVAIL',
  CapEmploi: 'CAP_EMPLOI',
  MissionLocale: 'MISSION_LOCALE',
  Departement: 'DEPARTEMENT',
} as const;
export const networks = Object.values(Network);
export type Network = ValuesType<typeof Network>;
export const networkTranslation: Record<Network, string> = {
  [Network.FranceTravail]: 'France Travail',
  [Network.CapEmploi]: 'Cap Emploi',
  [Network.Departement]: 'Département',
  [Network.MissionLocale]: 'Mission locale',
};

export const Population = {
  Population1: 'POPULATION_1',
  Population2: 'POPULATION_2',
  Population3: 'POPULATION_3',
} as const;
export const populations = Object.values(Population);
export type Population = ValuesType<typeof Population>;
export const populationTranslation: Record<Population, string> = {
  [Population.Population1]: 'Population 1',
  [Population.Population2]: 'Population 2 (sans visualisation)',
  [Population.Population3]: 'Population 3 (avec visualisation)',
};
export const populationTranslationWithDetails: Record<Population, string> = {
  [Population.Population1]: "Population 1 : sans visualisation pour l'usager et le conseiller",
  [Population.Population2]:
    "Population 2 : sans visualisation pour l'usager, avec visualisation pour le conseiller",
  [Population.Population3]: "Population 3 : avec visualisation pour l'usager et le conseiller",
};
export const populationShortTranslation: Record<Population, string> = {
  [Population.Population1]: 'Pop 1',
  [Population.Population2]: 'Pop 2',
  [Population.Population3]: 'Pop 3',
} as const;

export type YoungAdultFile = {
  imiloId: string;
  firstname: string;
  lastname: string;
  birthDate: Date;
  gender: Gender | null;
  nationalSupportPlan: NationalSupportPlan | null;
  network: Network;
  population: Population;
  imiloCodeStructure?: string;
  observations: Observation[];
};

export type YoungAdultFileInfoFromImilo = Pick<
  YoungAdultFile,
  'imiloId' | 'firstname' | 'lastname' | 'birthDate' | 'imiloCodeStructure' | 'nationalSupportPlan'
>;

export type UserFileInfo = Pick<
  YoungAdultFile,
  | 'imiloId'
  | 'firstname'
  | 'lastname'
  | 'birthDate'
  | 'imiloCodeStructure'
  | 'network'
  | 'nationalSupportPlan'
  | 'gender'
  | 'population'
>;

type ObservationSummary = {
  firstObservationDate: string | null;
  lastObservationDate: string | null;
  observationCount: number;
};

export type YoungAdultFileWithObservationSummary = YoungAdultFile & ObservationSummary;

export const createMockYoungAdultFileWithObservationSummary = (
  file: Partial<YoungAdultFileWithObservationSummary>
): YoungAdultFileWithObservationSummary => ({
  imiloId: 'MOCK-IMILO-ID',
  firstname: 'Jeanne',
  lastname: 'Dark',
  birthDate: new Date(1999, 1, 1),
  gender: Gender.Female,
  nationalSupportPlan: NationalSupportPlan.PACEA,
  network: Network.FranceTravail,
  population: Population.Population2,
  observations: [],
  firstObservationDate: null,
  lastObservationDate: null,
  observationCount: 0,
  ...file,
});
