import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useEffect, useState } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import { date, object, string } from 'yup';
import { PopulationInfoIcon } from '~/modules/progressDashboard/components/PopulationInfoIcon';
import {
  Gender,
  genderTranslation,
  NationalSupportPlan,
  nationalSupportPlans,
  Network,
  networkTranslation,
  Population,
  populations,
  populationTranslation,
  populationTranslationWithDetails,
} from '~/modules/progressDashboard/infrastructure/youngAdultFile.schema';
import { SelectFormControl } from '~/shared/components/SelectFormControl';
import { StyledButton } from '~/shared/components/StyledButton';
import { StyledTextField } from '~/shared/components/StyledTextfield';
import { theme } from '~/shared/theme/theme';

const FIRSTNAME_FIELD = 'Prénom';
const LASTNAME_FIELD = 'NOM';
const BIRTHDATE_FIELD = 'Date de naissance *';
const GENDER_FIELD = 'Genre';
const NETWORK_FIELD = 'Nom du réseau';
const NATIONAL_SUPPORT_PLAN_FIELD = 'Dispositif national';
const POPULATION_FIELD = 'Population';

const ADD_YOUNG_ADULT_DIALOG_TITLE = 'Ajouter un usager';
const EDIT_YOUNG_ADULT_DIALOG_TITLE = "Modifier les informations de l'usager";
const ADD_YOUNG_ADULT_DIALOG_CANCEL_BUTTON = 'Annuler';
const ADD_YOUNG_ADULT_DIALOG_SUBMIT_BUTTON = 'Ajouter';
const EDIT_YOUNG_ADULT_DIALOG_SUBMIT_BUTTON = 'Modifier';
const NOT_EDITABLE_WARNING = '⚠️ Cette information ne pourra pas être modifiée';

type Props = {
  defaultValues?: AddYoungAdultFormValues;
  editMode?: boolean;
  onCancelClicked: () => void;
  onSubmitClicked: (values: AddYoungAdultFormValues) => void;
} & DialogProps;

export type AddYoungAdultFormValues = {
  lastname: string;
  firstname: string;
  birthDate: Date;
  gender: Gender | null;
  network: Network;
  nationalSupportPlan: NationalSupportPlan | null;
  population: Population;
};

const validationSchema = object({
  lastname: string().required(),
  firstname: string().required(),
  birthDate: date().required(),
  gender: string().required(),
  network: string().required(),
  nationalSupportPlan: string().required(),
  population: string().required(),
}).defined();

export const AddYoungAdultDialog = ({
  defaultValues,
  editMode = false,
  onSubmitClicked,
  onCancelClicked,
  ...props
}: Props) => {
  const [birthDate, setBirthDate] = useState<Date | null>(defaultValues?.birthDate ?? null);

  const { handleSubmit, register, setValue } = useForm<AddYoungAdultFormValues>({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (defaultValues?.birthDate === undefined) return;
    setValue('birthDate', defaultValues?.birthDate, {
      shouldDirty: true,
    });
  }, [defaultValues?.birthDate, setValue]);

  useEffect(() => {
    if (!editMode || defaultValues?.population === undefined) return;
    setValue('population', defaultValues.population, {
      shouldDirty: false,
    });
  }, [defaultValues?.population, editMode, setValue]);

  const onInvalid = (errors: FieldErrors) => {
    console.error('errors', errors);
  };

  const onDateChange = (newDate: Date | null) => {
    if (newDate === null) return;

    setBirthDate(newDate);
    setValue('birthDate', newDate, {
      shouldDirty: true,
    });
  };

  return (
    <Dialog {...props}>
      <DialogTitle variant="h6" paddingBottom="0px !important">
        {editMode ? EDIT_YOUNG_ADULT_DIALOG_TITLE : ADD_YOUNG_ADULT_DIALOG_TITLE}
      </DialogTitle>
      <Box
        component="form"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={handleSubmit(onSubmitClicked, onInvalid)}
      >
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(5),
            paddingTop: theme.spacing(2),
            width: '400px',
          }}
        >
          <StyledTextField
            {...register('lastname')}
            defaultValue={defaultValues?.lastname}
            inputProps={{
              'aria-label': LASTNAME_FIELD,
              style: { textTransform: 'uppercase' },
            }}
            label={LASTNAME_FIELD}
            placeholder={LASTNAME_FIELD}
            required
            sx={{ height: 'fit-content', marginTop: theme.spacing(2) }}
            type="text"
          />
          <StyledTextField
            {...register('firstname')}
            inputProps={{
              'aria-label': FIRSTNAME_FIELD,
            }}
            defaultValue={defaultValues?.firstname}
            label={FIRSTNAME_FIELD}
            placeholder={FIRSTNAME_FIELD}
            required
            sx={{ height: 'fit-content' }}
            type="text"
          />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              format="dd-MM-yyyy"
              label={BIRTHDATE_FIELD}
              onChange={onDateChange}
              sx={{
                '&.MuiPickersYear-yearButton': {
                  textAlign: 'center',
                },
              }}
              value={birthDate}
            />
          </LocalizationProvider>
          <SelectFormControl<AddYoungAdultFormValues, Gender>
            defaultValue={defaultValues?.gender ?? ''}
            fieldName="gender"
            items={Object.values(Gender)}
            label={GENDER_FIELD}
            register={register}
            required={true}
            translation={genderTranslation}
          />
          <SelectFormControl<AddYoungAdultFormValues, Network>
            defaultValue={defaultValues?.network ?? ''}
            fieldName="network"
            items={Object.values(Network)}
            label={NETWORK_FIELD}
            register={register}
            required={true}
            translation={networkTranslation}
          />
          <SelectFormControl<AddYoungAdultFormValues, NationalSupportPlan>
            defaultValue={defaultValues?.nationalSupportPlan ?? ''}
            fieldName="nationalSupportPlan"
            items={nationalSupportPlans}
            label={NATIONAL_SUPPORT_PLAN_FIELD}
            register={register}
            required={true}
          />
          {editMode ? (
            <Stack marginX={theme.spacing(1)}>
              <Typography variant="body1" color="textPrimary">
                {POPULATION_FIELD}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {defaultValues?.population &&
                  populationTranslationWithDetails[defaultValues?.population]}
              </Typography>
            </Stack>
          ) : (
            <Box>
              <Box display="flex" alignItems="center">
                <SelectFormControl<AddYoungAdultFormValues, Population>
                  defaultValue={defaultValues?.population ?? ''}
                  fieldName="population"
                  items={populations}
                  label={POPULATION_FIELD}
                  register={register}
                  required={true}
                  translation={populationTranslation}
                />
                <PopulationInfoIcon />
              </Box>
              <Typography
                variant="body2"
                color="textSecondary"
                marginLeft={theme.spacing(1)}
                marginTop={theme.spacing(1)}
              >
                {NOT_EDITABLE_WARNING}
              </Typography>
            </Box>
          )}
          <DialogActions>
            <StyledButton fullWidth={true} onClick={onCancelClicked}>
              {ADD_YOUNG_ADULT_DIALOG_CANCEL_BUTTON}
            </StyledButton>
            <StyledButton fullWidth={true} variant="contained" type="submit">
              {editMode
                ? EDIT_YOUNG_ADULT_DIALOG_SUBMIT_BUTTON
                : ADD_YOUNG_ADULT_DIALOG_SUBMIT_BUTTON}
            </StyledButton>
          </DialogActions>
        </DialogContent>
      </Box>
    </Dialog>
  );
};
