import { SurveyButtonProps } from '~/modules/observation/components/ObservationSection/views/SurveyButton';

const FIRST_SURVEY_BUTTON_DESCRIPTION_REGULAR = 'Ne connaît pas et';
const FIRST_SURVEY_BUTTON_DESCRIPTION_BOLD = " ne voit pas l'intérêt";
const FIRST_BUTTON_IMAGE_PATH = '/first_image_button.svg';

const SECOND_SURVEY_BUTTON_DESCRIPTION_REGULAR = 'Ne connaît pas mais';
const SECOND_SURVEY_BUTTON_DESCRIPTION_BOLD = " voit l'intérêt";
const SECOND_BUTTON_IMAGE_PATH = '/second_image_button.svg';

const THIRD_SURVEY_BUTTON_DESCRIPTION_REGULAR = 'Connaît mais';
const THIRD_SURVEY_BUTTON_DESCRIPTION_BOLD = ' ne mobilise pas encore';
const THIRD_BUTTON_IMAGE_PATH = '/third_image_button.svg';

const FOURTH_BUTTON_DESCRIPTION_REGULAR = 'Connaît et';
const FOURTH_BUTTON_DESCRIPTION_BOLD = ' mobilise de façon accompagnée';
const FOURTH_BUTTON_IMAGE_PATH = '/fourth_image_button.svg';

const FIFTH_BUTTON_DESCRIPTION_BOLD = 'Mobilise en autonomie';
const FIFTH_BUTTON_IMAGE_PATH = '/fifth_image_button.svg';

export type AutonomyLevelProps = Omit<SurveyButtonProps, 'onClick' | 'selected'>;
export const autonomyLevels: AutonomyLevelProps[] = [
  {
    labels: [
      {
        text: FIRST_SURVEY_BUTTON_DESCRIPTION_REGULAR,
      },
      {
        text: FIRST_SURVEY_BUTTON_DESCRIPTION_BOLD,
        style: {
          fontWeight: 'bold',
        },
      },
    ],
    image: FIRST_BUTTON_IMAGE_PATH,
  },
  {
    labels: [
      { text: SECOND_SURVEY_BUTTON_DESCRIPTION_REGULAR },
      {
        text: SECOND_SURVEY_BUTTON_DESCRIPTION_BOLD,
        style: {
          fontWeight: 'bold',
        },
      },
    ],
    image: SECOND_BUTTON_IMAGE_PATH,
  },
  {
    labels: [
      { text: THIRD_SURVEY_BUTTON_DESCRIPTION_REGULAR },
      {
        text: THIRD_SURVEY_BUTTON_DESCRIPTION_BOLD,
        style: {
          fontWeight: 'bold',
        },
      },
    ],
    image: THIRD_BUTTON_IMAGE_PATH,
  },
  {
    labels: [
      {
        text: FOURTH_BUTTON_DESCRIPTION_REGULAR,
      },
      {
        text: FOURTH_BUTTON_DESCRIPTION_BOLD,
        style: {
          fontWeight: 'bold',
        },
      },
    ],
    image: FOURTH_BUTTON_IMAGE_PATH,
  },
  {
    labels: [
      {
        text: FIFTH_BUTTON_DESCRIPTION_BOLD,
        style: {
          fontWeight: 'bold',
        },
      },
    ],
    image: FIFTH_BUTTON_IMAGE_PATH,
  },
];
