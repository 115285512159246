import { CircularProgress, TableBody } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AUTONOMY_PROGRESSION_ROUTE_PATH } from '~/core/router';
import {
  sortYoungAdults,
  YoungAdultSorting,
} from '~/modules/progressDashboard/domain/sortYoungAdults';
import {
  hasNoYoungAdult,
  populationShortTranslation,
  useYoungAdultFilesQuery,
} from '~/modules/progressDashboard/infrastructure';
import { EmptyYoungAdultTable } from '~/modules/progressDashboard/views/ProgressionDashboardCard/EmptyYoungAdultTable';
import {
  ActionsCell,
  DimmedNameCell,
  NameCell,
} from '~/modules/progressDashboard/views/ProgressionDashboardCard/ProgressDashboardTableCell';
import { StyledCenteredTableContent } from '~/modules/progressDashboard/views/ProgressionDashboardCard/StyledCenteredTableContent.style';
import {
  StyledProgressTableBodyCell,
  StyledProgressTableRow,
} from '~/modules/progressDashboard/views/ProgressionDashboardCard/StyledTable.style';
import { getDate } from '~/shared/date/utils';

export const EMPTY_YOUNG_LIST = "Vous n'avez pas encore d'usagers en suivi.";
export const YOUNG_ADULT_TABLE_ROW_LABEL = 'user-table-row-label';
export const YOUNG_ADULT_FILE_TABLE_BODY = 'user-file-table-body';
const EMPTY_POPULATION = '-';
const EMPTY_NATIONAL_SUPPORT = '-';
const EMPTY_DIAGNOSIS_DATE = 'Aucun diagnostic';

type Props = {
  sorting: YoungAdultSorting | undefined;
};

export const ProgressDashboardTableContent = ({ sorting }: Props) => {
  const [lineClickDisabled, setLineClickDisabled] = useState(false);
  const { data: youngAdultList, isLoading } = useYoungAdultFilesQuery();

  const navigate = useNavigate();

  if (isLoading) {
    return (
      <TableBody>
        <StyledProgressTableRow clickable={false}>
          <StyledProgressTableBodyCell>
            <StyledCenteredTableContent>
              <CircularProgress />
            </StyledCenteredTableContent>
          </StyledProgressTableBodyCell>
        </StyledProgressTableRow>
      </TableBody>
    );
  }

  if (hasNoYoungAdult(youngAdultList)) {
    return <EmptyYoungAdultTable />;
  }

  return (
    <TableBody aria-label={YOUNG_ADULT_FILE_TABLE_BODY}>
      {(sortYoungAdults(youngAdultList ?? [], sorting) ?? []).map((youngAdult) => (
        <StyledProgressTableRow
          key={youngAdult.imiloId}
          onClick={() => {
            if (lineClickDisabled) return;
            navigate(`${AUTONOMY_PROGRESSION_ROUTE_PATH}/${youngAdult.imiloId}`);
          }}
          aria-label={`${YOUNG_ADULT_TABLE_ROW_LABEL}-${youngAdult.imiloId}`}
        >
          <NameCell textValue={youngAdult.firstname} />
          <NameCell textValue={youngAdult.lastname} />
          <DimmedNameCell
            textValue={
              youngAdult.population
                ? populationShortTranslation[youngAdult.population]
                : EMPTY_POPULATION
            }
            isDimmed={youngAdult.population === null}
          />
          <DimmedNameCell
            textValue={youngAdult.nationalSupportPlan ?? EMPTY_NATIONAL_SUPPORT}
            isDimmed={youngAdult.nationalSupportPlan === null}
          />
          <DimmedNameCell
            textValue={getDate(youngAdult.firstObservationDate) ?? EMPTY_DIAGNOSIS_DATE}
            isDimmed={youngAdult.firstObservationDate === null}
          />
          <DimmedNameCell
            textValue={getDate(youngAdult.lastObservationDate) ?? EMPTY_DIAGNOSIS_DATE}
            isDimmed={youngAdult.lastObservationDate === null}
          />
          <ActionsCell
            imiloId={youngAdult.imiloId}
            youngAdult={youngAdult}
            setLineClickDisabled={setLineClickDisabled}
          />
        </StyledProgressTableRow>
      ))}
    </TableBody>
  );
};
