import { Stack } from '@mui/material';
import { ReactElement } from 'react';
import { Header } from '~/core/components/header/Header';

interface PageWithHeaderProps {
  children: ReactElement;
}

export const PageWithHeader = ({ children }: PageWithHeaderProps) => {
  const headerSize = '64px';
  return (
    <Stack paddingTop={headerSize} height="100%">
      <Header />
      {children}
    </Stack>
  );
};
